<template>
  <sidebar-menu :is-open="isOpenMenu" @close="isOpenMenu = false"></sidebar-menu>
  <dashboard-header @toggle="isOpenMenu = !isOpenMenu" :is-open="isOpenMenu"></dashboard-header>
  <section class="pcoded-main-container">
    <div class="pcoded-wrapper limitWidth">
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <router-view :key="$route.fullPath"></router-view>
              <verify-phone />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent, ref, onMounted, onUnmounted, computed, watch } from 'vue'
import { useStore } from 'vuex'

const script = 'window.$crisp=[];window.CRISP_WEBSITE_ID="site_id";CRISP_TOKEN_ID="support_token";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();'

export default {
  name: 'DashboardWrapper',
  components: {
    'sidebar-menu': defineAsyncComponent(() => import('../layout/SidebarMenu.vue')),
    'dashboard-header': defineAsyncComponent(() => import('../layout/DashboardHeader.vue')),
    'verify-phone': defineAsyncComponent(() => import('@/application/components/elements/PhoneVerify.vue')),
  },
  setup() {
    const isOpenMenu = ref(false)
    let scriptElement
    const store = useStore()
    const license = computed(() => store.getters.getLicense)

    watch(() => license.value, (val) => {
      if (val) {
        if (!window.$crisp) {
          insertCrispScript(val.support_token)
        }
      }
    })

    const setEmail = () => {
      window.$crisp.push(['set', 'user:email', license.value.email])
    }
    const insertCrispScript = (supportToken) => {
      const scriptWithId = script.replace('site_id', '70dc5431-9f0c-4b32-b44f-85bbb7970637')
      let scriptWithToken = null
      if (supportToken) {
        scriptWithToken = scriptWithId.replace('support_token', supportToken)
      } else {
        scriptWithToken = scriptWithId.replace('CRISP_TOKEN_ID="support_token";', '')
      }
      scriptElement = document.createElement('script')
      scriptElement.innerText = scriptWithToken
      document.body.appendChild(scriptElement)
      scriptElement.onload = setEmail()
    }
    onMounted(() => {
      store.dispatch('getLicense')
        .then(() => { store.dispatch('getSavedFormsData') })
    })
    onUnmounted(() => {
      // crisp chat use only inside dashboard
      if (window.$crisp) {
        window.$crisp.push(['do', 'session:reset'])
        window.$crisp.push(['do', 'chat:hide'])
      }
    })
    return {
      isOpenMenu,
    }
  },
}
</script>
